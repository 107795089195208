<template>
  <h1>HP</h1>
</template>

<script>
export default {
  name: 'Hp',
  components: { // Test

  },
  data() {
    return {

    }
  }
}
</script>
